import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import { graphql } from 'gatsby';
import ModalVideo from 'react-modal-video';
import Modal from 'react-modal';
import classNames from 'classnames';
import { navigate } from '@reach/router';
import { Controller, Scene } from 'react-scrollmagic';
import { Line } from 'rc-progress';
import '../../node_modules/react-modal-video/scss/modal-video.scss';

import scrollTo from '../helpers/scrollTo';

import DataTestimonials from '../data/testimonials.json';
import DataClients from '../data/clients.json';
import DataNewsletter from '../data/newsletter.json';

import Layout from '../components/Layout';
import Button from '../components/Button';
import NavbarSimple from '../components/NavbarSimple';
import FooterSimple from '../components/FooterSimple';
import CardStatic from '../components/CardStatic';
import GetBook from '../components/GetBook';
import Clients from '../components/sections/Clients';
import ContactB from '../components/sections/ContactB';

import Bars1Src from '../img/svg/bars-5.svg';
import Bars2Src from '../img/svg/bars-6.svg';
import Bars3Src from '../img/svg/bars-7.svg';
import Bars4Src from '../img/svg/bars-8.svg';
import BookBgSrc from '../img/svg/pageSolutions/book_bg.svg';
import VideoBgSrc from '../img/svg/pageSolutions/video_bg.svg';
import ServicesBgSrc from '../img/svg/pageSolutions/services_bg.svg';
import HeroSrc from '../img/svg/pageSolutions/hero_bg.svg';
import HeroFullSrc from '../img/svg/pageSolutions/hero_full_bg.svg';
import HeroUnderSrc from '../img/svg/pageSolutions/hero_under_bg.svg';
import ArrowSrc from '../img/svg/pageSolutions/arrow_long.svg';
import BackgroundGraphSrc from '../img/svg/pageSolutions/graph_bg.svg';
import Illustration from '../img/svg/pageSolutions/hero_illustration.svg';
import IllustrationRealTime from '../img/png/pageSolutions/illustration_real_time.png';
import IllustrationRealTime1 from '../img/png/pageSolutions/illustration_real_time_1.png';
import IllustrationRealTime2 from '../img/png/pageSolutions/illustration_real_time_2.png';
import IllustrationRealTime3 from '../img/png/pageSolutions/illustration_real_time_3.png';

import IconPlay from '../../static/img/icon-play.svg';
import VideoMp4Src from '../../static/video/real-time-processing.mp4';
import VideoWebmSrc from '../../static/video/real-time-processing.webm';

export const ComplexCloneTemplate = ({ 
  hero,
  services,
  clients,
  graph,
  book,
  video,
  contact,
  location
}) => {

  const [isToggled, setToggled] = useState(false);
  const [isToggledModal, setToggledModal] = useState(false);
  const [isMainSVGLoaded, setIsMainSVGLoaded] = useState(false);
  const [isMainFullSVGLoaded, setIsMainFullSVGLoaded] = useState(false);
  let dt = DataTestimonials.workWithUs;

  const clientsFiltered =
    DataClients.items && DataClients.items.length && clients && clients.logos && clients.logos.length
      ? DataClients.items.filter(o => clients.logos.find(o2 => o.id == o2.value)) : null; // must be ==
  
  const testimonialFiltered =
    dt && dt.length && contact && contact.clients && contact.clients.length
      ? dt.filter(o => contact.clients.find(o2 => o.id == o2.value)) : null; // must be ==

  let newsletterFirst = book.newsletter !== null && book.newsletter.length && book.newsletter[0].value;
  let newsletterFiltered = DataNewsletter.items.find(x => x.id === newsletterFirst);

  let handleBookStatus = (param) => {
    if(param === true) {
      navigate('#book-success');
    }
  }

  let handleScrollContact = () => {
    if(location.hash === '#contact-success') {
      setTimeout(() => {
        scrollTo('realtimeContact', 'auto')
      }, 100)
    }
  }
  
  const classNameLogos = classNames('Clients--noPadding', { 
    'Clients--oneRow': clientsFiltered.length > 4 
  });

  const classNameHeroBg = classNames('Realtime__hero__background', {
    'Realtime__hero__background--noImage': !isMainSVGLoaded,
    'Realtime__hero__background--fullImage': isMainFullSVGLoaded
  });

  useEffect(() => {
    handleScrollContact();
  });

  function renderProgress(progress)  {
    const images = [IllustrationRealTime, IllustrationRealTime1, IllustrationRealTime2, IllustrationRealTime3];
    const pr = progress * 100;
    let title = '';
    let image;

    if (pr <= 25) {
      image = images[0];
    } else if (pr >= 25 && pr <= 50) {
      title = 'Input';
      image = images[1]; 
    } else if (pr >= 50 && pr <= 75) {
      title = 'Architecture';
      image = images[2]; 
    } else if (pr >= 75 && pr <= 100) {
      title = 'Output';
      image = images[3]; 
    }

    return {
      image: image,
      title: title,
      progress: pr
    }
  }

  return (
    <div className="Realtime">

      <NavbarSimple />
      
      <section className="Realtime__hero">
        <div className={classNameHeroBg}>
          <SVG
            src={HeroFullSrc}
            uniquifyIDs={false}
            className="Realtime__hero__background-imageFull"
            onLoad={() => setIsMainFullSVGLoaded(true)}
          />
          <SVG
            src={HeroSrc}
            uniquifyIDs={false}
            className="Realtime__hero__background-image"
            onLoad={() => setIsMainSVGLoaded(true)}
          />
          <div className="Realtime__hero__background-empty"></div>
          <SVG
            src={HeroUnderSrc}
            uniquifyIDs={false}
            className="Realtime__hero__background-under"
          />
        </div>

        <div className="Realtime__container Realtime__hero__container">
          <div className="Realtime__hero__content">
            <div className="Realtime__hero-title-wrapper">
              <SVG
                src={Bars1Src}
                className="Realtime__hero-bar Realtime__hero-bar--1"
              />
  
              <SVG
                src={Bars2Src}
                className="Realtime__hero-bar Realtime__hero-bar--2"
              />

              <h1 className="Realtime__hero__title">{hero.title}</h1>
            </div>

            <p className="Realtime__hero__text">{hero.text}</p>

            <Button
              className="Realtime__hero__btn"
              onClick={() => scrollTo('howItWorks')}
            >
              {hero.btnText1}
            </Button>
            <Button
              onClick={() => scrollTo('readUseCase', 'smooth', -5)}
              className="Realtime__hero__btn"
              variants={['empty']}
            >
              {hero.btnText2}
            </Button>
          </div>

          <div className="Realtime__hero__illustration">
            <img
              src={Illustration}
              alt="servers"
              className="Realtime__hero__image"
            />
          </div>
        </div>
        <div className="Realtime__clients">
          <div className="Realtime__container">
            <h5 className="Realtime__clients__title">{clients.title}</h5>
            <div className="Realtime__clients__items">
              <Clients clients={clientsFiltered} className={classNameLogos} />
            </div>
          </div>
        </div>
      </section>

      <section className="Realtime__graph" id="howItWorks">
        <div className="Realtime__graph__mobile">
          <div className="Realtime__graph__background">
            <SVG
              src={BackgroundGraphSrc}
              uniquifyIDs={false}
              className="Realtime__graph__background-image"
            />
            <div className="Realtime__container Realtime__graph__container">
              <h2 className="Realtime__graph__title">{graph.title}</h2>
              <img src={IllustrationRealTime} alt="Graph"/>
            </div>
          </div>
        </div>
        <div className="Realtime__graph__desktop">
          <Controller>
            <Scene duration="300%" triggerHook="0.15" pin={{ pushFollowers: false }}>
              {progress => (
                <div className="Realtime__graph__background">
                  <div id="scrollPin">
                    <SVG
                      src={BackgroundGraphSrc}
                      uniquifyIDs={false}
                      className="Realtime__graph__background-image"
                    />
                    <div className="Realtime__container Realtime__graph__container">
                      <h2 className="Realtime__graph__title">{graph.title}</h2>
                      <h4 className="Realtime__graph__subtitle">{renderProgress(progress).title}&nbsp;</h4>
                      <div 
                        className="Realtime__graph__image" 
                        style={{backgroundImage: `url(${renderProgress(progress).image})`}} 
                      />
                      <Line percent={renderProgress(progress).progress} strokeWidth="1" strokeColor="#1fdc6b" />
                    </div>
                  </div>
                </div>
              )}
            </Scene>
          </Controller>
        </div>
      </section>

      <section className="Realtime__book" id="readUseCase">
        <div className="Realtime__book__background">
          <SVG
            src={BookBgSrc}
            uniquifyIDs={false}
            className="Realtime__book__background-image"
          />
        </div>
        <div className="Realtime__container Realtime__book__container">
          <div className="Realtime__book__content">
            <h2 className="Realtime__book__title">{book.title}</h2>
            <p className="Realtime__book__text">{book.text}</p>
            <Button
              className="Realtime__book__btn"
              onClick={() => setToggledModal(true)}
            >
              {book.btnText}
            </Button>
            <Modal
              isOpen={isToggledModal}
              onRequestClose={() => setToggledModal(false)}
              ariaHideApp={false}
              className="Modal"
            >
              <GetBook 
                title={book.title}
                text={book.text}
                mailchimpValue={newsletterFiltered && newsletterFiltered.value}
                onStatus={handleBookStatus}
              />
            </Modal>
          </div>
          <div className="Realtime__book__illustration">
            <img
              src={'/img/ebook.png'} 
              alt="ebook"
            />
          </div>
        </div>
      </section>

      <section className="Realtime__video">
        <div className="Realtime__video__background">
          <SVG
            src={VideoBgSrc}
            uniquifyIDs={false}
            className="Realtime__video__background-image"
          />
        </div>
        <div className="Realtime__container">
          <div className="Realtime__video__container">
            <div className="Realtime__video-title-wrapper">
              <SVG
                src={Bars3Src}
                className="Realtime__video-bar Realtime__video-bar--1"
              />

              <SVG
                src={Bars4Src}
                className="Realtime__video-bar Realtime__video-bar--2"
              />

              <h3 className="Realtime__video__title">
                {video.title}
              </h3>
            </div>
            <div className="Realtime__video__arrow">
              <SVG
                src={ArrowSrc}
                uniquifyIDs={false}
                className="Realtime__video__arrow__img"
              />
            </div>
          </div>
          <div className="Realtime__video__item" onClick={() => setToggled(!isToggled)}>
            <video muted="muted" loop="loop" autoPlay="autoplay" poster="">
              <source src={VideoMp4Src} type="video/mp4" />
              <source src={VideoWebmSrc} type="video/webm" />
              Your browser does not support the video tag
            </video>
            <SVG
              src={IconPlay}
              uniquifyIDs={false}
              className="Realtime__video__item__btn"
            />
          </div>
          <ModalVideo 
            channel='youtube' 
            isOpen={isToggled} 
            videoId='CyrQ5B0exqU' 
            onClose={() => setToggled(false)} 
          />
        </div>
      </section>

      <section className="Realtime__services">
        <div className="Realtime__services__background">
          <SVG
            src={ServicesBgSrc}
            uniquifyIDs={false}
            className="Realtime__services__background-image"
          />
        </div>

        <div className="Realtime__services__container">

          <ul className="Realtime__services__items">
            {services && services.items &&
              services.items.map((item, i) => (
                <CardStatic
                  key={i}
                  tagName="li"
                  className="Realtime__services__item"
                  iconSrc={item.icon && item.icon.publicURL}
                  title={item.name}
                  text={item.text}
                />
              ))}
          </ul>

          <div className="Realtime__services__content">
            <h2 className="Realtime__services__title">{services.heading}</h2>

            <p className="Realtime__services__text">
              {services.text}
            </p>
          </div>
        </div>
      </section>
      
      <ContactB
        id="realtimeContact"
        className={'Realtime__contact'} 
        title={contact.title}
        titleForm={contact.titleForm}
        text={contact.text}
        textClients={contact.textClients}
        testimonials={testimonialFiltered}
      />

      <FooterSimple />
    </div>
  )
};

const ServicesPage = (props) => {
  const { frontmatter } = props.data.markdownRemark;
  const mergedProps = {...frontmatter, ...{ location: props.location }};

  return (
    <Layout 
      variants={['backgrounded']} 
      meta={frontmatter.meta} 
      showContact={false} 
      showNewsletter={false} 
      showFooter={false}
      showNavbar={false}
    >
      <ComplexCloneTemplate {...mergedProps} />
    </Layout>
  );
};

ServicesPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default ServicesPage;

export const servicesPageQuery = graphql`
  query ComplexCloneTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "complex-copy-page" } }) {
      frontmatter {
        meta {
          title
          description
          image {
            publicURL
            name
          }
          hide_robot
        }

        hero {
          title
          text
          btnText1
          btnText2
        }

        clients {
          logos {
            label
            value
          }
          title
        }

        graph {
          title 
        }

        book {
          title
          text
          btnText
          newsletter {
            label
            value
          }
        }

        video {
          title 
        }

        services {
          heading
          text
          items {
            icon {
              publicURL
              name
            }
            name
            text
          }
        }

        contact {
          title
          titleForm
          text
          textClients
          clients {
            label
            value
          }
        }
      }
    }
  }
`;
