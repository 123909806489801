import React, { Component } from 'react';
import { throttle } from 'lodash';
import classNames from 'classnames';
import { Link } from 'gatsby';

import LogoSrc from '../img/svg/logo.svg';

class NavbarSimple extends Component {
  state = {
    isOpen: false,
    isFloating: false,
  };

  toggleMenu = () => {
    this.setState(prev => ({ isOpen: !prev.isOpen }));
  };

  get isScrolledToTop() {
    return window.scrollY === 0;
  }

  onScroll = () => {
    this.setState({ isFloating: !this.isScrolledToTop });
  };
  constOnScrollEvent = throttle(this.onScroll, 300);

  componentDidMount() {
    if (!this.isScrolledToTop) {
      this.setState({ isFloating: true });
    }
    window.addEventListener('scroll', this.constOnScrollEvent);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.constOnScrollEvent);
  }

  render() {
    const { isOpen, isFloating } = this.state;
    const backgroundClassName = classNames('Navbar__background', {
      'Navbar__background--visible': isOpen || isFloating,
    });

    return (
      <nav className="Navbar" role="navigation" aria-label="main-navigation">
        <div className={backgroundClassName} />

        <div className="Navbar__container">

          <Link to={'/'} className="Navbar__logo-wrapper" title="Logo">
            <img src={LogoSrc} alt="logo" className="Navbar__logo" />
          </Link>

          <div className="Navbar__back">
            Back to <Link to={'/'} className="">getindata.com</Link>
          </div>
        </div>
      </nav>
    );
  }
}

export default NavbarSimple;
